<template>
  <div
    v-if="shouldAskNotifPermission"
    class="card bg-info text-center text-white my-3">
    <div class="card-body">
      <h2>Hidupkan notifikasi</h2>
      <p>Dapatkan notifikasi realtime untuk mengetahui status pembayaran.</p>

      <div class="d-flex justify-content-center">
        <button
          v-if="!loading.getToken"
          class="btn border border-white text-white me-3"
          @click="hideAskForRequest = true">
          Nanti Saja
        </button>
        <button
          class="btn btn-light text-primary"
          @click="getToken()"
          :disabled="loading.getToken">
          {{ loading.getToken ? 'Mengaktifkan...' : 'Hidupkan' }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { getFirebaseToken, loading } from '@/core/firebase-messaging'
import { shouldAskNotifPermission, hideAskForRequest } from '@/core/notifications'

export default {
  setup () {
    const getToken = async () => {
      await getFirebaseToken()
    }

    return {
      getToken,
      loading,
      shouldAskNotifPermission,
      hideAskForRequest
    }
  }
}
</script>
