<template>
  <div id="profile-order">
    <div class="container">
      <app-bar
        :noDivider="
          $route.path == '/profile/order'
          || $route.path == `/profile/order/${$route.params.id_order}/detail`
          || $route.path == `/profile/offline-order/${$route.params.id_order}/detail`
          || $route.path == '/profile/setting/contact'
          || $route.path == '/profile/setting/personal-data'
          || $route.path == '/profile/setting/password'"
        :title="$route.meta?.overrideAppBarTitle || 'Profil'"
        previousLink="/"
        class="sub"
        :class="{
          'd-none': ($route.path == '/profile/order'
            || $route.path == `/profile/order/${$route.params.id_order}/detail`
            || $route.path == `/profile/offline-order/${$route.params.id_order}/detail`
          )
        }" />

      <div class="row">
        <div
          class="col-md-3 pt-md-0"
          :class="{
            'd-none d-md-block': $route.meta?.noProfileCard
          }">
          <card-profile v-if="user.status_text !== 'Belum Registrasi'" />
        </div>

        <div
          class="col-md-9"
          :class="{
            'px-3': ($route.path != `/profile/order/${$route.params.id_order}/detail`
            && $route.path != `/profile/offline-order/${$route.params.id_order}/detail`),
            'px-0': ($route.path == `/profile/order/${$route.params.id_order}/detail`
            || $route.path == `/profile/offline-order/${$route.params.id_order}/detail`)
          }">
          <request-notif-permission />
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardProfile from '@/components/profile/CardProfile'
import AppBar from '@/components/AppBar.vue'
import RequestNotifPermission from '@/components/RequestNotifPermission.vue'

export default {
  components: {
    CardProfile,
    AppBar,
    RequestNotifPermission
  },
  setup () {
    const user = JSON.parse(localStorage.getItem('user'))

    return {
      user
    }
  }
}
</script>
